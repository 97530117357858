import React from 'react';
import PropTypes from 'prop-types';
import { conf } from '../../../domain/services/configService';
import { dateService } from '../../../domain/services/dateService';
import { SmartLink } from '../../SmartLink';
import { IconArrowLeft } from '../../Icon/IconArrowLeft';
import { IconArrowRight } from '../../Icon/IconArrowRight';
import {
  schdlController,
  schdlControllerArrow,
  schdlControllerArrowLeft,
  schdlControllerArrowRight,
  schdlControllerCont,
  schdlControllerItem
} from '../../Styles/schedule';

const DATE_FORMAT = 'm/d/Y';

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
  day => conf.locale[day]
);

const getDaysToShow = date => {
  const startOfWeek = dateService.getStartOfWeek(date);
  const daysToShow = [startOfWeek];
  for (let i = 1; i < 7; i += 1) {
    daysToShow.push(dateService.addDay(dateService.clone(startOfWeek), i));
  }
  return daysToShow;
};

export const ScheduleDayPicker = ({
  channel,
  date,
  dayLeftLimit,
  dayRightLimit
}) => {
  const daysToShow = getDaysToShow(date);
  const prevWeekDay = dateService.addDay(dateService.clone(daysToShow[0]), -1);
  const nextWeekDay = dateService.addDay(
    dateService.clone(daysToShow.slice(-1).pop()),
    1
  );

  return (
    <div className="schdl__controller" css={schdlController}>
      {prevWeekDay > dayLeftLimit ? (
        <SmartLink
          to={`${document.location.pathname}#schedule=${
            channel.machine_name
          }/${dateService.format(prevWeekDay, DATE_FORMAT)}`}
          title="prev"
        >
          <span
            className="schdl__controller__arrow-left schdl__controller__arrow"
            css={[schdlControllerArrowLeft, schdlControllerArrow]}
            aria-label="left"
            role="button"
          >
            <IconArrowLeft />
          </span>
        </SmartLink>
      ) : (
        <span
          className="schdl__controller__arrow-left schdl__controller__arrow disabled"
          css={[schdlControllerArrowLeft, schdlControllerArrow]}
          aria-label="left"
        >
          <IconArrowLeft />
        </span>
      )}

      <div className="schdl__controller__cont" css={schdlControllerCont}>
        <ul className="schdl__controller__list">
          {daysToShow.map(day => {
            const dayTime = day.getTime();
            const dateTime = date.getTime();
            return (
              <li
                key={`key-${dayTime}`}
                className={`schdl__controller__item ${
                  dayTime === dateTime ? 'active' : ''
                }`}
                css={schdlControllerItem}
              >
                <SmartLink
                  to={`${document.location.pathname}#schedule=${
                    channel.machine_name
                  }/${dateService.format(day, DATE_FORMAT)}`}
                >
                  {`${weekDays[day.getDay()]} ${day.getDate()}`}
                </SmartLink>
              </li>
            );
          })}
        </ul>
      </div>

      {nextWeekDay < dayRightLimit ? (
        <SmartLink
          to={`${document.location.pathname}#schedule=${
            channel.machine_name
          }/${dateService.format(nextWeekDay, DATE_FORMAT)}`}
          title="next"
        >
          <span
            className="schdl__controller__arrow-right schdl__controller__arrow"
            css={[schdlControllerArrowRight, schdlControllerArrow]}
          >
            <IconArrowRight />
          </span>
        </SmartLink>
      ) : (
        <span
          className="schdl__controller__arrow-right schdl__controller__arrow disabled"
          css={[schdlControllerArrowRight, schdlControllerArrow]}
        >
          <IconArrowRight />
        </span>
      )}
    </div>
  );
};

ScheduleDayPicker.propTypes = {
  channel: PropTypes.objectOf(PropTypes.any).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  dayLeftLimit: PropTypes.instanceOf(Date).isRequired,
  dayRightLimit: PropTypes.instanceOf(Date).isRequired
};
